<template>
  <div class="w-100 d-flex flex-column h-100">
    <BaseToolBar title="Accueil" subtitle="Packs accueil pour les nouveaux salariés"></BaseToolBar>
    <base-form class="mr-md-3" shadow :updateBtn="false">
      <base-form-group title="Documents à l'accueil du salarié" dense icon="book">
        <div class="d-flex pt-3">
          <div class="w-100">
            <div v-for="accueil in data.packsAccueil" :key="accueil.id" class="d-flex align-items-center">
              <button-circle
                v-if="!inPackAccueil(accueil.id)"
                @click="assignAccueil(accueil.id)"
                name="circle"
                size="18"
                class="mr-1"
                :loading="loading"
              ></button-circle>
              <button-circle
                v-if="inPackAccueil(accueil.id)"
                @click="revokeAccueil(accueil.id)"
                name="check-circle"
                size="18"
                class="mr-1"
                :loading="loading"
              ></button-circle>
              <span>{{ accueil.description }}</span>
            </div>
          </div>
          <div class="w-100 border p-3 rounded text-center d-flex flex-column">
            <div v-if="accueil_realise" class="m-auto">
              <div>
                <BaseIcon name="check" width="50" height="50" class="text-success"></BaseIcon>
              </div>
              <div>L'accueil a été validé par le collaborateur</div>
              <div class="text-primary cursor-pointer" @click="deleteAccueil">Supprimer la validation</div>
            </div>
            <div v-else>
              <div>
                <BaseIcon name="x" width="50" height="50" class="text-danger"></BaseIcon>
              </div>
              <div>L'accueil n'a pas été validé par le collaborateur</div>
              <div class="text-primary cursor-pointer" @click="forceAccueil">Forcer la validation</div>
            </div>
          </div>
        </div>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import BaseToolBar from "@/components/bases/ToolBar.vue";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { errHandler } from "@/helpers.js";
import { mapActions } from "vuex";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: { ButtonCircle, BaseToolBar, BaseForm, BaseFormGroup, BaseIcon },
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  methods: {
    ...mapActions({
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
      _assignAccueil: "collaborateurs/assignAccueil",
      _revokeAccueil: "collaborateurs/revokeAccueil",
    }),
    updateCollaborateur() {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    assignAccueil(accueil_id) {
      this.feedback = {};
      this.loading = true;
      this._assignAccueil({
        collaborateur_id: this.id,
        accueil_id: accueil_id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    revokeAccueil(accueil_id) {
      this.feedback = {};
      this.loading = true;
      this._revokeAccueil({
        collaborateur_id: this.id,
        accueil_id: accueil_id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    inPackAccueil(id) {
      if (this.accueils.some((c) => c.accueil_id == id)) return true;
      return false;
    },
    deleteAccueil() {
      this.accueil_realise = false;
      this.updateCollaborateur();
    },
    forceAccueil() {
      this.accueil_realise = true;
      this.updateCollaborateur();
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
      accueils: "collaborateurs/accueils",
    }),
    ...mapFields("collaborateurs", ["collaborateur.id", "collaborateur.accueil_realise"]),
  },
};
</script>
<style lang="css"></style>
